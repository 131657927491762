import React, { useEffect, useState } from 'react';  
import { useData } from '../config/DataContext'; 
import { useNavigate } from 'react-router-dom';
import { FaStar, FaRegStar } from 'react-icons/fa';  
import FileUpload from './FileUpload';
import ImagePopup from './ImagePopup';
import Modal from 'react-modal';
 
Modal.setAppElement('#root');  

const MyStudioImages = ({UserIdQuery, PlatformQuery, CollectionTypeQuery, OnlyFavoritesQuery, ImageCountQuery, onStatusChange, refreshImages, CSelectedImages})  => { 
    const [selectedImagesStatus, setSelectedImagesStatus] = useState('start_loading');
    const [data5row, setData5row] = useState([]);    
    const [favorites5row, setFavorites5row] = useState([]);
    const [dataAll, setDataAll] = useState([]);    
    const [favoritesdataAll, setFavoritesdataAll] = useState([]); 
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImagesFull, setselectedImagesFull] = useState([]); 
    const [showAll, setshowAll] = useState('Show All'); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenImage, setisModalOpenImage] = useState(false);
    const [isModalImageId, setisModalImageId] = useState(0);
    const [imagecounts, setimagecounts] = useState(0);
    const [imageStatus, setimageStatus] = useState('');
    const [deletedImage, setdeletedImage] = useState(0);
    const [refresh, setRefresh] = useState(false);
    const fileUploadRef = React.createRef();  
    const configData = useData(); 
    let token = localStorage.getItem('Authorization');
 
    const navigate  = useNavigate(); 
    
    useEffect(() => {  
     
      if (UserIdQuery && PlatformQuery && CollectionTypeQuery) { 
          fetchData(ImageCountQuery);  
      }
    }, [UserIdQuery, CollectionTypeQuery]); 
    
    useEffect(() => {  
        fetchData(0);  
    }, [refreshImages]); 

    const fetchData = (imagesCount) => { 
        const response = fetch(`${configData.apiUrl}Design/GetUserDesignImages?userId=${UserIdQuery}&platform=${PlatformQuery}&imageCollectionType=${CollectionTypeQuery}&onlyFavorites=${OnlyFavoritesQuery}&imageCount=${imagesCount}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token 
                },
              })
        .then((response) => response.json())
        .then((data) => {
      
          let dataImages=[];
          if (Array.isArray(data.images)) {
            dataImages = data.images;
          }
         
          if (!OnlyFavoritesQuery)
          setimagecounts(data.imageCount); 

       
          if (CSelectedImages && CSelectedImages.length>0)
          {  
              const ImagesS = CSelectedImages.map(image => image.id);
              dataImages = dataImages.map(image => ({
                ...image,
                checkStatus: ImagesS.includes(image.id),
              }))
 
              const customImagesFull= CSelectedImages  
              setSelectedImages(ImagesS);
              setselectedImagesFull(customImagesFull);
              setSelectedImagesStatus('delete_post');
              onStatusChange(ImagesS,CollectionTypeQuery,customImagesFull);
              setimageStatus('Edit');
               
          } 
          else 
          {
            setimageStatus('Images');
            dataImages = dataImages.map(image => ({
              ...image,
              checkStatus: false,
            })) 
          }
                
          if (imagesCount==5) 
          {
            setData5row(dataImages);  
            setFavorites5row(dataImages.filter(item => item.favorite)); 
            
            setFavorites5row(favorites5row => {
            const updatedFavorites = [...dataImages.filter(item => item.favorite)];
                
              if (updatedFavorites.length < 5) { 
                const additionalFavorites = favoritesdataAll.filter(
                favItem => !updatedFavorites.some(item => item.id === favItem.id)
                ).slice(0, 5 - updatedFavorites.length);
                return [...updatedFavorites, ...additionalFavorites];
              }
                return updatedFavorites;
            });      
            setFavorites5row(favorites5row.map(image => ({
              ...image,
              checkStatus: selectedImages.includes(image.id),
            })));
            
          } 
          else 
          {
            if (!OnlyFavoritesQuery)
            { 
              setDataAll(dataImages.map(image => ({
                ...image,
                checkStatus: selectedImages.includes(image.id),
              })));
              setData5row(dataImages.slice(0, 5)); 
            }
          
      
            setFavoritesdataAll(dataImages.filter(item => item.favorite).map(image => ({
              ...image,
              checkStatus: selectedImages.includes(image.id),
            })));
 
            setFavorites5row(dataImages.filter(item => item.favorite).slice(0, 5));
            setFavorites5row(favorites5row => { 
              if (dataImages.filter(item => item.favorite).slice(0, 5).length < 5) { 
                const additionalFavorites = favoritesdataAll.filter(
                favItem => !dataImages.filter(item => item.favorite).slice(0, 5).some(item => item.id === favItem.id)
                ).slice(0, 5 - dataImages.filter(item => item.favorite).slice(0, 5).length);
                return [...favorites5row, ...additionalFavorites];
              }
              return favorites5row;
            });  
            setFavorites5row(favorites5row.map(image => ({
              ...image,
              checkStatus: selectedImages.includes(image.id),
            })));
            //  console.log(JSON.stringify(dataImages.filter(item => item.favorite).slice(0, 5)));
          }
         
        })
        .catch((error) => {
          console.log(error); 
        });
    };

    const handleShowAll = () => {   
      if (showAll == 'Show All')
      { 
        setshowAll('Hide');   
        if (dataAll.length==0 || favoritesdataAll.length==0) 
        {
          fetchData(0);    
        }   
      } 
      else 
      {
        setData5row(data5row.map(image => ({
          ...image,
          checkStatus: selectedImages.includes(image.id),
        })));
      
        setFavorites5row(favorites5row => { 
          if (favorites5row.length < 5) { 
            const additionalFavorites = favoritesdataAll.filter(
            favItem => !favorites5row.some(item => item.id === favItem.id)
            ).slice(0, 5 - favorites5row.length);
            return [...favorites5row, ...additionalFavorites];
          }
          return favorites5row;
        }); 

        setFavorites5row(favorites5row.map(image => ({
          ...image,
          checkStatus: selectedImages.includes(image.id),
        })));
       
        setshowAll('Show All');
          
      }   
    };
    
    const openModal = () => {
      if (dataAll.length==0 || favoritesdataAll.length==0) 
      {
       // fetchData(0);    
      }
      setIsModalOpen(true);
    };

    const closeModal = (status) => {
      setIsModalOpen(false);
      if (status)
         fetchData(0);
    };
     
    const openModalImage = (imageId) => {
      if (dataAll.length==0 || favoritesdataAll.length==0) 
      {
        fetchData(0);    
      }
      setisModalImageId(imageId);
      setisModalOpenImage(true); 
    };

    const closeModalImage = (status,imageId) => {
      setisModalOpenImage(false);
     
      if (status=='deleted')
      { 
        setdeletedImage(imageId);

       // navigate(0); 
      } 
    };

    const toggleFavorite = async (id, fdata) => {
      const item = fdata.find(item => item.id === id);   
      let pramprocess = item.favorite ? "Remove" : "Add";
  
      const requestBody = {
          process: pramprocess,
          userId: UserIdQuery,  
          action: "Favorite",
          forType: "Image",
          forId: id, 
          note: ""
      };
  
      try {
          const response = await fetch(`${configData.apiUrl}Common/TakeAction`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': token 
              },
              body: JSON.stringify(requestBody),
          });
  
          if (response) { 
              const result = await response.json();
             
              if (result) { 
                setData5row(data5row.map(item =>
                    item.id === id ? { ...item, favorite: !item.favorite } : item
                ));
                setDataAll(dataAll.map(item =>
                    item.id === id ? { ...item, favorite: !item.favorite } : item
                ));
    
                const favitem = favorites5row.find(item => item.id === id); 
    
                if (favitem) { 
                    setFavorites5row(favorites5row.filter(item => item.id !== id));
                } else {
                    const newItem = { ...item, favorite: true };
                    setFavorites5row(prevFavorites => [...prevFavorites, newItem]);
                }
    
                const favallitem = favoritesdataAll.find(item => item.id === id); 
                if (favallitem) {
                    setFavoritesdataAll(favoritesdataAll.filter(item => item.id !== id));
                } else {
                    const newItemall = { ...item, favorite: true };
                    setFavoritesdataAll(prevFavorites => [...prevFavorites, newItemall]);
                }
  
              //  setshowAll(showAll === 'Show All' ? 'Hide' : 'Show All');
            }
          } else {
              console.error('Error in response:', response.statusText);
          }
      } catch (error) {
          console.error('Favorite states update error:', error);
      }
    };
  
    const handleCheckboxChange  = (id,srcImage,isChecked,imagedata) => {
      let updatedSelectedImages;
      let updatedSelectedImagesFull;
      let pushImage=0;

      if (selectedImages.includes(id) && isChecked==false) { 
          pushImage=id;
          updatedSelectedImages = selectedImages.filter(imageId => imageId !== id);
          updatedSelectedImagesFull  = selectedImagesFull.filter(imageId => imageId.id !== id);
         
      } else { 

        const newImage = {
          id: id,
          srcImage: srcImage,
          CollectionType: CollectionTypeQuery
        };  
        updatedSelectedImages = [...selectedImages, id]; 
        updatedSelectedImagesFull = [...selectedImagesFull, newImage];
      }
  
      setSelectedImages(updatedSelectedImages);
      setselectedImagesFull(updatedSelectedImagesFull);

      if (updatedSelectedImages.length > 0) {
          setSelectedImagesStatus('delete_post'); 
      } else {
          setSelectedImagesStatus('start_loading');
      }
   
      if (onStatusChange) {
          const newStatus = updatedSelectedImages.length > 0 ? 'delete_post' : 'start_loading';
          onStatusChange(updatedSelectedImages,CollectionTypeQuery,updatedSelectedImagesFull,pushImage); 
      }
  
        setDataAll(dataAll.map(image => ({
            ...image,
            checkStatus: updatedSelectedImages.includes(image.id),
        })));
      
        setData5row(data5row.map(image => ({
            ...image,
            checkStatus: updatedSelectedImages.includes(image.id),
        })));
       
        setFavorites5row(favorites5row.map(image => ({
          ...image,
          checkStatus: updatedSelectedImages.includes(image.id),
        })));

        setFavoritesdataAll(favoritesdataAll.map(image => ({
            ...image,
            checkStatus: updatedSelectedImages.includes(image.id),
        })));
          
    };
    
    const renderFavorites = (newdataAll) => {  
                       
      if (newdataAll) {
        if (newdataAll.length === 0) {
            if (showAll === 'Hide') newdataAll = favoritesdataAll
            else newdataAll = favoritesdataAll.slice(0,5);  }
      
      }
       
     if (deletedImage>0) newdataAll   = newdataAll.filter(image => image.id !==deletedImage); 
    
    return newdataAll.map(image => (
     
          <div className='photos_favorite' key={image.id}> 
                <img src={`data:image/${image.mediaTypeName};base64,${image.content}`} 
                      className='photos_favorite' alt={`${image.tag}`}  id= {image.id}
                        onClick={() => openModalImage(image.id)}  />   
 
                      <Modal
                        isOpen={isModalOpenImage}
                        onRequestClose={closeModal}
                        contentLabel="Popup Modal"
                        className="modal"
                        overlayClassName="overlay"
                        >
                        <ImagePopup
                        imageId = {isModalImageId}
                          isPopupOpen={isModalOpenImage}
                          closePopup={closeModal} 
                          CollectionType = {CollectionTypeQuery}
                          Status = {imageStatus}
                        />
                      </Modal>
                <div className={`favorite_icon ${image.favorite ? 'filled' : ''}`}
                  onClick={() => toggleFavorite(image.id,newdataAll)} >
                  {image.favorite ? <FaStar /> : <FaRegStar/>}
                </div> 
                <div className='checkbox_icon'>
                  <label className="checkbox_container">
                      <input
                        type="checkbox"
                        className='checkbox_custom'
                        id={`checkbox-${image.id}`}
                        checked={image.checkStatus}
                        onChange={(e) => handleCheckboxChange(image.id, `data:image/${image.mediaTypeName};base64,${image.content}`, e.target.checked,newdataAll)}
                      />
                      <span className="checkbox_label"> </span>
                      <label htmlFor={`checkbox-${image.id}`}></label>
                  </label>
                </div>
          </div> 
    ));
  };
 
  const renderAllData = (newdataAll) => {
    if (newdataAll) {
      if (newdataAll.length !== 0) {
        if (showAll === 'Hide') newdataAll = dataAll;
        else newdataAll = data5row;
      }
      if (newdataAll.length === 0) {
        newdataAll = data5row;
      }
    }
   
    if (deletedImage>0) newdataAll   = newdataAll.filter(image => image.id !==deletedImage); 
 
    return newdataAll.map(image => (  
      <div className='photos_favorite' key={image.id}> 
          <img 
            src={`data:image/${image.mediaTypeName};base64,${image.content}`} 
            className='photos_favorite' 
            alt={`${image.tag}`}  
            id={image.id} 
            onClick={() => openModalImage(image.id)} 
          />      
          <Modal
            isOpen={isModalOpenImage}
            onRequestClose={closeModalImage}
            contentLabel="Upload Modal"
            className="modal"
            overlayClassName="overlay"
          >
            <ImagePopup
              imageId={isModalImageId} 
              isPopupOpen={isModalOpenImage}
              closePopup={closeModalImage} 
              CollectionType={CollectionTypeQuery}
              Status = {imageStatus}
            />
          </Modal>
        <div className={`favorite_icon ${image.favorite ? 'filled' : ''}`}
          onClick={() => toggleFavorite(image.id, newdataAll)}  >
          {image.favorite ? <FaStar /> : <FaRegStar />}
        </div>  
        <div className='checkbox_icon'>
          <label className="checkbox_container">
            <input
              type="checkbox"
              className='checkbox_custom'
              id={`checkbox-${image.id}`}
              checked={image.checkStatus}
              onChange={(e) => handleCheckboxChange(image.id, `data:image/${image.mediaTypeName};base64,${image.content}`, e.target.checked,newdataAll)}
            />
            <span className="checkbox_label"> </span>
            <label htmlFor={`checkbox-${image.id}`}></label>
          </label>
        </div>
      </div>
     ));
  };
  
  return (  
    <div className='myuploads_creations'>  
      <div className='aiimages_preview' >   
        <div className='mc_titlebar'>
            <div className='mc_title'>
                {CollectionTypeQuery ==='CustomImages' ? ( 'MY UPLOADS')
                : ('AI CREATIONS')} 
            </div>
            {CollectionTypeQuery ==='CustomImages' ? (
             <div>
                <button onClick={openModal} className='showAll_button'>
                    <div className='myuploads_button'> 
                      <div className='myuploads_button_AppIcon'>
                          <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22 22" fill="none">
                          <path d="M6.05001 14.3C3.92372 14.3 2.20001 12.5763 2.20001 10.45C2.20001 8.46079 3.70862 6.82394 5.64413 6.62115C5.55009 6.26321 5.50001 5.88746 5.50001 5.50001C5.50001 3.06995 7.46996 1.10001 9.90001 1.10001C11.9667 1.10001 13.7007 2.52492 14.1729 4.44593C14.3943 4.41565 14.6203 4.40001 14.85 4.40001C17.5838 4.40001 19.8 6.6162 19.8 9.35001C19.8 12.0838 17.5838 14.3 14.85 14.3H12.1V10.3556L13.5222 11.7778C13.9518 12.2074 14.6483 12.2074 15.0778 11.7778C15.5074 11.3482 15.5074 10.6518 15.0778 10.2222L11.7778 6.92219C11.3483 6.49261 10.6518 6.49261 10.2222 6.92219L6.9222 10.2222C6.49262 10.6518 6.49262 11.3482 6.9222 11.7778C7.35177 12.2074 8.04825 12.2074 8.47783 11.7778L9.90001 10.3556L9.90001 14.3H6.05001Z" fill="#FEF6F6"/>
                          <path d="M9.90001 14.3H12.1L12.1 19.8C12.1 20.4075 11.6075 20.9 11 20.9C10.3925 20.9 9.90001 20.4075 9.90001 19.8L9.90001 14.3Z" fill="#FEF6F6"/>
                          </svg>
                      </div>
                      <div className='myuploads_button_label'>
                          Upload
                      </div>
                    </div>
                </button> 
                  <Modal
                  isOpen={isModalOpen}
                  onRequestClose={closeModal}
                  contentLabel="Upload Modal"
                  className="modal"
                  overlayClassName="overlay"
                  >
                  <FileUpload
                    ref={fileUploadRef}
                    isPopupOpen={isModalOpen}
                    closePopup={closeModal} 
                    currentFileCount ={dataAll.length}
                  />
                  </Modal>
              </div>):(<div></div>)}
        </div>
         
         {OnlyFavoritesQuery ?  (renderFavorites(favorites5row) ) : (renderAllData(data5row))}
           
        </div>
        <div className='table_separator'>
            <div className='separator'/>
        </div>
        <div className='showAll'>
          <div className='showAll_text'><button onClick={handleShowAll} className='showAll_button'> {showAll} 
            &nbsp; ({imagecounts})</button></div>
        </div>
   
    </div>
 
  );  
 
};
   
export default MyStudioImages;

  
   