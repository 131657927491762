import React, { useState, useRef, useEffect } from 'react';
import '../styles/imagePopup.css';
import { useData } from '../config/DataContext.jsx';
import ImageMagnifier from '../components/ImageMagnifiers.jsx';

const ImagePopup = ({ isPopupOpen, closePopup, CollectionType, imageId, Status }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [mediaTypeName, setMediaTypeName] = useState('');
  const [isZooming, setIsZooming] = useState(false);
  const [loading, setLoading] = useState(true);  
  const magnifierRef = useRef(null);
  const imageRef = useRef(null);
  const configData = useData();
  let token = localStorage.getItem('Authorization');

  useEffect(() => {
    if (imageId) {
      setLoading(true);  
      fetch(`${configData.apiUrl}Common/GetImage?imageId=${imageId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': token
        }
      })
        .then(response => response.json())
        .then(data => {
          setImageSrc(data.content);
          setMediaTypeName(data.mediaTypeName);
          setLoading(false); 
        })
        .catch(error => {
          console.error('Error fetching image:', error);
          setLoading(false); 
        });
    }
  }, [imageId, configData.apiUrl]);

  const handleDeleteClick = async () => {
    const requestBody = [imageId];
    try {
      let response;
      if (CollectionType === 'CustomImages') {
        response = await fetch(`${configData.apiUrl}User/DeleteCustomImages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
          body: JSON.stringify(requestBody),
        });
      } else if (CollectionType === 'DesignImages') {
        response = await fetch(`${configData.apiUrl}Design/DeleteImages`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
          body: JSON.stringify(requestBody),
        });
      }

      if (response.ok) {
        closePopup('deleted', imageId);
        console.log('Files deleted successfully');
      } else {
        console.error('Error deleting files');
      }
    } catch (error) {
      console.error('Error deleting files', error);
    }
  };

  if (!isPopupOpen) return null;

  return (
    <div className="popup" onClick={closePopup}>
      <div className="popup_titlebar-and-toolbar">
        <div className="popup_leading">
          <button onClick={closePopup} className="showAll_button">
            <div className="popup_trailling_leading_appIcon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21ZM15.5976 13.5074C16.1379 14.0552 16.1379 15.0062 15.5748 15.5692C15.0041 16.1397 14.053 16.1397 13.5051 15.592L10.4995 12.5869L7.48628 15.5996C6.96886 16.1245 5.97968 16.1474 5.4166 15.5768C4.85352 15.0138 4.87635 14.0324 5.39377 13.5151L8.40699 10.4948L5.39377 7.48967C4.87635 6.97234 4.85352 5.98333 5.4166 5.42035C5.97968 4.84977 6.96886 4.8802 7.48628 5.39753L10.4995 8.40261L13.5051 5.40514C14.053 4.86498 15.0041 4.85737 15.5748 5.42796C16.1379 5.99094 16.1379 6.94952 15.5976 7.49728L12.592 10.4948L15.5976 13.5074Z" fill="white"/>
              </svg>
            </div>
          </button>
        </div>
        <div className="popup_trailling">
          {Status === 'Images' ? (
            <button onClick={handleDeleteClick} className="showAll_button">
              <div className="popup_trailling_button">
                <div className="popup_trailling_appIcon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" fill="none">
                    <path d="M16.625 6.125L15.8661 16.7497C15.8007 17.6655 15.0387 18.375 14.1205 18.375H6.87946C5.96134 18.375 5.19932 17.6655 5.13391 16.7497L4.375 6.125M8.75 9.625V14.875M12.25 9.625V14.875M13.125 6.125V3.5C13.125 3.01675 12.7332 2.625 12.25 2.625H8.75C8.26675 2.625 7.875 3.01675 7.875 3.5V6.125M3.5 6.125H17.5" stroke="#FF453A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className="popup_trailling_label">
                  Delete
                </div>
              </div>
            </button>
          ) : (
            <div></div>
          )}
        </div>
      </div>
      
      <div className="image-zoom-container">
        {loading ? (
          <div className="image-placeholder">Loading...</div>  
        ) : (
          <ImageMagnifier 
            src={`data:image/${mediaTypeName};base64,${imageSrc}`}
            alt="Zoomed" 
          />
        )}
      </div>
    </div>
  );
};

export default ImagePopup;
