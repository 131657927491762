import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {DataProvider} from '../config/DataContext';
import AIDesign from '../pages/AIDesign'; 
import MyStudio from '../pages/MyStudio'; 
import MyCreation from '../pages/MyCreation'; 
import MyStudioDetails from '../pages/MyStudioDetails'; 
import CreationList from '../pages/CreationList'; 
import CreationListDetails from '../pages/CreationListDetails'; 
import UserProfile from '../pages/UserProfile'; 
import Login from '../pages/Login'; 
import SpotLight from '../pages/SpotLight'; 
import SpotLightList from '../pages/SpotLightList'; 
import {BalanceProvider} from './BalanceContext';
import {DesignProvider} from './DesignContext'; 
import MainLayout from '../pages/MainLayout';
 
const AppRoutes = () => {
  return (       
    <DataProvider>  
      <Router>     
        <BalanceProvider>  
            <DesignProvider>
              <MainLayout> 
                  <Routes >
                    <Route path="/AIDesign" element={<AIDesign />} />
                    <Route path="/MyStudio" element={<MyStudio />} /> 
                    <Route path="/MyStudioDetails" element={<MyStudioDetails />} /> 
                    <Route path="/MyCreation" element={<MyCreation />} />           
                    <Route path="/CreationList" element={<CreationList />} />      
                    <Route path="/CreationListDetails" element={<CreationListDetails />} />
                    <Route path="/UserProfile" element={<UserProfile />} />          
                    <Route path="/SpotLightList" element={<SpotLightList />} />       
                    <Route path="/SpotLight" element={<SpotLight />} />    
                    <Route path="/:param1/:param2/spotlight" element={<SpotLight />}  />
                    <Route path="/Login" element={<Login />} />    
                  </Routes >             
                </MainLayout>         
              </DesignProvider>    
        </BalanceProvider>      
      </Router> 
    </DataProvider> 
  );
};

export default AppRoutes;