import React  from 'react'; 
import {useData} from '../config/DataContext';  
import {useNavigate} from 'react-router-dom';
import '../styles/alert.css'; 
const Alert = ({ isPopupOpen, closePopup, title,message,status,creationId} ) => {
  const configData = useData(); 
  const navigate = useNavigate();

  const deleteCreation = () => {
    const requestBodyDelete = [creationId];  
    try {
        const response =  fetch(`${configData.apiUrl}Creation/DeleteCreations`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          
          },
          body: JSON.stringify(requestBodyDelete),
        }).then((response) => response.json());

        if (response) {   
        navigate('/MyCreation');
        navigate(0);
        } 
      } 
    catch (error) {
      console.error('', error); 
        
    };
  }
     
  if (!isPopupOpen) return null;

  return (
    <div className="popup-overlay" onClick={closePopup}>
      <div className="alert" onClick={(e) => e.stopPropagation()}>

        <div className="alertapIcon">
          {status ==='Delete' ?
              (<svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
                 <path d="M41.167 15.6667L39.2878 41.9754C39.1258 44.2431 37.2389 46 34.9655 46H17.0352C14.7617 46 12.8748 44.2431 12.7129 41.9754L10.8337 15.6667M21.667 24.3333V37.3333M30.3337 24.3333V37.3333M32.5003 15.6667V9.16667C32.5003 7.97005 31.5303 7 30.3337 7H21.667C20.4704 7 19.5003 7.97005 19.5003 9.16667V15.6667M8.66699 15.6667H43.3337" stroke="#FF453A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>) : (status ==='Published' ? (
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="53" viewBox="0 0 52 53" fill="none">
                 <path d="M28.1667 35.1667H26V26.5H23.8333M26 17.8333H26.0217M45.5 26.5C45.5 37.2696 36.7696 46 26 46C15.2304 46 6.5 37.2696 6.5 26.5C6.5 15.7304 15.2304 7 26 7C36.7696 7 45.5 15.7304 45.5 26.5Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> ) : ( 
                <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 52 53" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M43.4383 14.2615C44.4536 15.2769 44.4536 16.9231 43.4383 17.9385L22.6383 38.7385C21.6229 39.7538 19.9767 39.7538 18.9613 38.7385L8.56133 28.3385C7.54596 27.3231 7.54596 25.6769 8.56133 24.6615C9.57669 23.6462 11.2229 23.6462 12.2383 24.6615L20.7998 33.223L39.7613 14.2615C40.7767 13.2462 42.4229 13.2462 43.4383 14.2615Z" fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                ))
          }
           
        </div>
          
        <div className="alertTitleBox">
            <div className="alertTitle">
                { title !=='Published' ? (title) :(<span></span>) } 
            </div>
            <div className="alertText">
                {message}
            </div>
        </div>
        <div className="alertSeparator"/>
          {status !=='Delete' ? (
            <button  className='showAll_button' onClick={closePopup}>
              <div className="alertlgotIt">
                  Got it
              </div>
            </button>) : 
            (<button  className='showAll_button' onClick={deleteCreation}>
              <div className="alertlgotItRed">
                    Delete
              </div>
            </button>)}
      </div>
         
    </div>
      
  );
};

export default Alert;