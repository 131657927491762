import React from 'react' 
import {useState, useRef} from "react";

import GenerateDesign from '../components/GenerateDesign';
import GenerateIteration from '../components/GenerateIteration'; 
import {useDesign , DesignProvider} from '../components/DesignContext'; 
import { useData } from '../config/DataContext'; 

export default function AIDesign ()  {
  return (  
    <DesignProvider>
      <MainComponent />
    </DesignProvider>   
  );
};

const MainComponent = () => { 
  const [UserIdQuery, setUserIdQuery] = useState(1);
  const [clickIteration, setclickIteration] = useState(0);
  const [PromptQuery, setprompt] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [divs, setDivs] = useState([]);
  const promptRef = useRef('');
  const configData = useData(); 
  const {designId} = useDesign();  
  const characterLimit = configData.characterLimit;

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const isButtonDisabled =  inputValue.length < characterLimit;
  const [placeholder, setPlaceholder] = useState('Enter prompt (min. ' + characterLimit + ' char.)'); 

  const handleSubmit = () => {
     
    if (designId !=null)
    {
      setPlaceholder('Refine or enter new prompt');
    } else  setPlaceholder('Enter prompt (min. ' + characterLimit + ' char.)');

    const promptvalue = promptRef.current.value;
 
    if (promptvalue !=='' || promptvalue !== null)
    {
      setprompt(promptvalue);
      let newDiv = null;
      let promptQ = promptvalue;
      if (designId ==null && UserIdQuery !=null && PromptQuery !=null) 
        newDiv=  <GenerateDesign key={divs.length} UserIdQuery={UserIdQuery} PromptQuery={promptQ} />;
      if (designId !=null  && PromptQuery !=null)
        newDiv=   <GenerateIteration key={divs.length} DesignIDQuery={designId} PromptQuery={promptQ} />;

      setDivs(prevDivs => [...prevDivs, newDiv]); 
      setclickIteration(clickIteration + 1);
 
    }  
  };
   
  return ( 
   
    <div className='content' >  

      <div className='messages_table'>
        <div className='messageia'> 
          <div className='ainame_rowplain'> 
            <div className='image_table_contents'/>  
            <div className='ainame'> AI GENERATOR </div> 
          </div>
          <div className='row_plain'> 
            <div className='table_contents'>  
              <div className='title_table_contents'>  
                  Hey you! I am XX, a tool designed to bring to life your wildest fashion concepts. 
                <li>For a better result, remember to add as many details  (category of the garment, color, fabric, fitting, etc).</li>
                <li>Each creation consumes 1 credit.</li>
              </div>
            </div>
          </div>
        </div>
      
        <div className='messageia'> 
            <div className='ainame_rowplain'> 
              <div className='messageuser_image' />  
              <div className='ainame'> USER NAME </div> 
            </div>
            <div className='row_plain'> 
              <div className='table_contents'>  
                <div className='title_table_contents'>  
                    Hey you! I am XX, a tool designed to bring to life your wildest fashion concepts. 
                  <li>For a better result, remember to add as many details  (category of the garment, color, fabric, fitting, etc).</li>
                  <li>Each creation consumes 1 credit.</li>
                </div> 
              </div>
            </div>

         
        </div> 
        {divs.map((divElement) => ( 
              <React.Fragment key={divElement.key}>
                {divElement}
              </React.Fragment>
            ))}
      </div>

      <div className='messagebox'>
        <div className='image_messagebox_left'> 
         <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 36 36" fill="none">
          <path d="M24.4544 6.45444C25.8603 5.04855 28.1397 5.04855 29.5456 6.45444C30.9515 7.86033 30.9515 10.1397 29.5456 11.5456L28.1184 12.9728L23.0272 7.88165L24.4544 6.45444Z" fill="#FEF6F6"/>
          <path d="M20.4816 10.4272L5.40002 25.5089V30.6H10.4912L25.5728 15.5184L20.4816 10.4272Z" fill="#FEF6F6"/>
          </svg>
        </div>

        <div className='messagebox_textarea'> 
          <textarea name="postContent"    value={inputValue}
            placeholder={placeholder} 
            className='messagebox_textarea_title'   onChange={handleInputChange}
            ref={promptRef}  />  
        </div>

        <div className='image_messagebox_right'>  
            <button type="submit" className='button_nostyle' onClick={handleSubmit} disabled={isButtonDisabled}>
              <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M19.0272 9.52721C19.7301 8.82426 20.8698 8.82426 21.5727 9.52721L28.7727 16.7272C29.4757 17.4302 29.4757 18.5698 28.7727 19.2728L21.5727 26.4728C20.8698 27.1757 19.7301 27.1757 19.0272 26.4728C18.3242 25.7698 18.3242 24.6302 19.0272 23.9272L23.1544 19.8L9.49995 19.8C8.50584 19.8 7.69995 18.9941 7.69995 18C7.69995 17.0059 8.50584 16.2 9.49995 16.2H23.1544L19.0272 12.0728C18.3242 11.3698 18.3242 10.2302 19.0272 9.52721Z" fill="white"/>
              </svg>
            </button> 
        </div> 
      </div>
    
    </div>
   
  )
}
