import React, { useState, useEffect } from 'react'; 
import { useData } from '../config/DataContext'; 
import Modal from 'react-modal';
import ImagePopup from '../components/ImagePopup';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { setDate } from 'date-fns';
import FormatDate from '../components/FormatDate'; 
 
Modal.setAppElement('#root');  

export default function CreationListDetails ()  {
  return <MainComponent />;
}

const MainComponent = () => {  
  const [categories, setCategories] = useState([]);
  const [colors, setColors] = useState([]);
  const [designedFor, setDesignedFor] = useState([]); 
  const [status, setStatus] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedColor, setselectedColor] = useState('');
  const [selectedDesignedFor, setselectedDesignedFor] = useState(''); 
  const [selectedStatus, setselectedStatus] = useState(''); 
  const [savedStatus, setsavedStatus] = useState(''); 
  const [selectedshopifyLink, setselectedshopifyLink] = useState(''); 
  const [selectedspotlightLink, setselectedspotlightLink] = useState(''); 
  const [selectedNote, setselectedNote] = useState(''); 
  const [selectedDate, setselectedDate] = useState(''); 
  const [selectedCreator, setselectedCreator] = useState(''); 
  const [dataAll, setdataAll] = useState([]);       
  const [Description, setDescription] = useState('');
  const [Title, setTitle] = useState(''); 
  const [isCategoryValid, setIsCategoryValid] = useState(true); 
  const [isColorValid, setisColorValid] = useState(true);
  const [isDesignedForValid, setisDesignedForValid] = useState(true);
  const [isTitleValid, setisTitleValid] = useState(true);
  const [isDescriptionValid, setisDescriptionValid] = useState(true); 
  const [imagecounts, setimagecounts] = useState(0); 
  const [isModalOpenImage, setisModalOpenImage] = useState(false);
  const [isModalImageId, setisModalImageId] = useState(0); 
 
  let token = localStorage.getItem('Authorization');
  const navigate = useNavigate(); 
  const location = useLocation();
  const configData = useData(); 

  const openModalImage = (imageId) => {
    setisModalImageId(imageId);
    setisModalOpenImage(true);
  };
 
  const maxLength = 500;
  const handleDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length <= maxLength) {
      setDescription(value);
    }
    setisDescriptionValid(true);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    setisTitleValid(true);
  };

  const clearText = () => {
    setTitle(''); 
  };

  const handleNoteChange = (event) => {
    setselectedNote(event.target.value); 
  };


  const handleSpotlightChange = (event) => {
    setselectedspotlightLink(event.target.value); 
  };

  const handleShopifyChange = (event) => {
    setselectedshopifyLink(event.target.value); 
  };
  
  useEffect(() => {
    const token = localStorage.getItem('Authorization');  
    
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`  
    };
  
   
    fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=Category`, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(data => setCategories(data))
      .catch(error => console.error('Error fetching categories:', error));
  
 
    fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=Color`, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(data => setColors(data))
      .catch(error => console.error('Error fetching colors:', error));
  
     
    fetch(`${configData.apiUrl}Common/GetCategoryItems?categoryName=DesignedFor`, {
      method: 'GET',
      headers: headers
    })
      .then(response => response.json())
      .then(data => setDesignedFor(data))
      .catch(error => console.error('Error fetching designed for:', error));
  
  }, [configData.apiUrl]); 
  
  
  const { creationId, creator } = location.state || {};

  useEffect(() => { 
    if (creationId)
    {   
        fetchData('Big');
        setselectedCreator(creator);
    }
 
  }, [creationId, navigate, location.pathname]);
 
  const fetchData = async (PlatformQuery) => {
    try { 
      const response = await fetch(
        `${configData.apiUrl}Creation/GetCreation?id=${creationId}&platform=${PlatformQuery}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token 
          },
        }
      );
      const data = await response.json();     
      if (data)
      { 
        setTitle(data.title);
        setDescription(data.description); 
        setselectedNote(data.note); 
        setselectedDate(data.createdAt);
        setselectedStatus(data.status);
        setsavedStatus(data.status);
        
        if (Array.isArray(data.images)) {
          setdataAll(data); 
          setimagecounts(data.images.length);
          
        } else {  
          setdataAll(data);    
          setimagecounts(0);   
        }

        if (Array.isArray(data.availableStatus)) 
        {
            setStatus(data.availableStatus);
        }

        if (data.spotlight) 
        {
          setselectedspotlightLink(data.spotlight.linkUrl);
          setselectedshopifyLink(data.spotlight.shopifyLinkUrl);
        }

        if (Array.isArray(data.categoryItems)) 
        {
          const categoryItem = data.categoryItems.find(item => item.category === 'Category');
          const colorItem = data.categoryItems.find(item => item.category === 'Color');
          const designedForItem = data.categoryItems.find(item => item.category === 'DesignedFor'); 
          setSelectedCategory(categoryItem ? categoryItem.id : ''); 
          setselectedColor(colorItem ? colorItem.id : '');
          setselectedDesignedFor(designedForItem ? designedForItem.id : '');
        } 
        else 
        {
          console.log('data.categoryItems is not an array or is undefined');
        }
      }
    
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreationClick = () => { 
    navigate(location.pathname, { replace: true, state: {} });
    navigate('/CreationList'); 
  };
 
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setIsCategoryValid(true);
  };
   
  const handleColorChange = (event) => {
    setselectedColor(event.target.value);
    setisColorValid(true);
  };
  
  const handleDesignedForChange = (event) => {
    setselectedDesignedFor(event.target.value);
    setisDesignedForValid(true);
  };

  const handleStatusChange = (event) => {
    setselectedStatus(event.target.value);  
  };
 
  const handleSubmitClick = () => () => { 
      if (selectedCategory === '') { 
        setIsCategoryValid(false);
        return;
      }

      if (selectedColor === '') { 
        setisColorValid(false);
        return;
      }

      if (selectedDesignedFor === '') { 
        setisDesignedForValid(false);
        return;
      }

      if (Title=='')
      { 
        setisTitleValid(false);
        return;
      }
      if (Description=='')
      { 
        setisDescriptionValid(false);
        return;
      }

      let UserId =1; 
      let CIids = [];
      CIids.push(selectedCategory);
      CIids.push(selectedColor);
      CIids.push(selectedDesignedFor); 
 
       const requestBodyUpdate = {
          id : creationId,
          userId: UserId,    
        };

        if (Title !== '') {
          requestBodyUpdate.title = Title;
        }

        if (Description !== '') {
          requestBodyUpdate.description = Description;
        }
       
        if (CIids.length>0) {
          requestBodyUpdate.categoryItemIds = CIids;
        }
        
        if (selectedStatus=='Spotlight' || selectedStatus=='Gallery') {
          requestBodyUpdate.spotlight = { 
            linkUrl : selectedspotlightLink,
            shopifyLinkUrl : selectedshopifyLink};
        }
  
        requestBodyUpdate.note= selectedNote;
        if (selectedStatus!=savedStatus)
        {
          requestBodyUpdate.status= selectedStatus;
        }
     
        console.log(JSON.stringify(requestBodyUpdate));

        
          const response =  fetch(`${configData.apiUrl}Creation/UpdateCreation?userRole=Admin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json', 
              'Authorization': token 
            },
            body: JSON.stringify(requestBodyUpdate),
          }).then(response => response.json())
          .then(() => { 
            navigate('/CreationList', { state: { refresh: true } });
          }) 
          .catch(error => {
            console.error('Error updating creation:', error);
          });

  
    };
   
    const getStatusColor = (status) => {
      switch (status) {
          case 'AwaitingApproval':
              return '#FFFFFF'; 
          case 'Spotlight':
              return '#EBAB00';
          case 'Gallery':
              return '#0D99FF';
          case 'ChangeRequested':
              return '#F24822';
          case 'Approved' :
              return '#14AE5C'; 
      }
  };

    const renderAllData = () => {
 
      if (Array.isArray(dataAll.images)) {
        return dataAll.images.map(image => (
          <div className='mystudioDetails_table_corner_photobox' key={image.id}>
            <img
              src={`data:image/${image.mediaTypeName};base64,${image.content}`}
              className='mystudioDetails_table_corner_photobox'
              id={image.id}
              alt={`Image ${image.id}`}
              onClick={() => openModalImage(image.id)}
            />
            <Modal
              isOpen={isModalOpenImage}
              onRequestClose={() => setisModalOpenImage(false)}
              contentLabel="Image Modal"
              className="modal"
              overlayClassName="overlay"
            >
              <ImagePopup
                imageId={isModalImageId}
                isPopupOpen={isModalOpenImage}
                closePopup={() => setisModalOpenImage(false)}
                CollectionType=""
                Status={'Details'}
              />
            </Modal>
          </div>
        ));
      } 
    };
    
  return (
    <div className='content'>
      <div className='MyStudioContent'> 
        <div className='navbar'> 
          <button onClick={handleCreationClick} className='showAll_button'>
            <div className='button_createpost'> 
            <div className='button_back_appIcon'>
                <svg xmlns="http://www.w3.org/2000/svg"   viewBox="0 0 35 36" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M16.9874 26.2374C16.304 26.9209 15.196 26.9209 14.5126 26.2374L7.51256 19.2374C6.82915 18.554 6.82915 17.446 7.51256 16.7626L14.5126 9.76256C15.196 9.07915 16.304 9.07915 16.9874 9.76256C17.6709 10.446 17.6709 11.554 16.9874 12.2374L12.9749 16.25L26.25 16.25C27.2165 16.25 28 17.0335 28 18C28 18.9665 27.2165 19.75 26.25 19.75H12.9749L16.9874 23.7626C17.6709 24.446 17.6709 25.554 16.9874 26.2374Z" fill="white"/>
                </svg>
              </div>
              <div className='button_createpost_label'>
                ADMIN
              </div>
           
            </div>
          </button>
          <div className='mystudioDetails_right'>
            <button onClick={handleSubmitClick('Update')} className='showAll_button'>
              <div className='button_myposts_creations'>
                <div className='button_myposts_creations_label'>
                  Update
                </div>
                <div className='button_update_appIcon'>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.69995 4.7001C4.69995 3.5403 5.64015 2.6001 6.79995 2.6001H11.615C12.172 2.6001 12.7061 2.82135 13.1 3.21517L16.6849 6.8001C17.0787 7.19392 17.3 7.72807 17.3 8.28502V17.3001C17.3 18.4599 16.3597 19.4001 15.2 19.4001H6.79995C5.64015 19.4001 4.69995 18.4599 4.69995 17.3001V4.7001ZM6.79995 11.0001C6.79995 10.4202 7.27005 9.9501 7.84995 9.9501H14.15C14.7298 9.9501 15.2 10.4202 15.2 11.0001C15.2 11.58 14.7298 12.0501 14.15 12.0501H7.84995C7.27005 12.0501 6.79995 11.58 6.79995 11.0001ZM7.84995 14.1501C7.27005 14.1501 6.79995 14.6202 6.79995 15.2001C6.79995 15.78 7.27005 16.2501 7.84995 16.2501H14.15C14.7298 16.2501 15.2 15.78 15.2 15.2001C15.2 14.6202 14.7298 14.1501 14.15 14.1501H7.84995Z" stroke="white" stroke-linejoin="round"/>
                  </svg>
                </div>
              </div>
            </button>
            
          </div>
        </div>
        <div className='tableCreationRow'>
          <div className='tableCreationDetailsBar'> 
                <div className='tableCreationDetailsColumn_1'>
                        <div className='columns_Header'> 
                              CREATOR
                          </div>   
                          <div className='columns_Text'> 
                              {selectedCreator} 
                          </div>  
                </div>
                <div className='tableCreationDetailsColumn_2'>
                        <div className='columns_Header'> 
                            STATUS
                          </div>   
                          <div className='columns_Text'> 
                            <div className='action_select'>
                              <select onChange={handleStatusChange} style={{ color: getStatusColor(selectedStatus) }}>
                                <option value={selectedStatus} >{selectedStatus} </option>
                                {status.map(sts => (
                                  <option key={sts} value={sts} className='option'   style={{ color: 'white' }}>
                                    {sts}
                                  </option>
                                ))}
                              </select>
                            
                          </div>
                          </div>  
                  </div>

                  <div className='tableCreationDetailsColumn_3'>
                        <div className='columns_Header'> 
                            DATE
                        </div>   
                        <div className='columns_Text'> 
                            {selectedDate && FormatDate(selectedDate) } 
                        </div>
                  </div>  
                
                  <div className='tableCreationDetailsColumn_4'>
                        <div className='columns_Header'> 
                           SPOTLIGHT LINK
                        </div>   
                        <div className='columns_Text'> 
                          <div className='column_TextBlack'>
                            <input type='text' placeholder='Value' value={selectedspotlightLink}  onChange={handleSpotlightChange}/>                     
                          </div> 
                        </div>
                  </div>  

                  <div className='tableCreationDetailsColumn_5'>
                        <div className='columns_Header'> 
                          SHOPIFY LINK
                        </div>   
                        <div className='columns_Text'> 
                          <div className='column_TextBlack'>
                               <input type='text' placeholder='Value' value={selectedshopifyLink}  onChange={handleShopifyChange} 
                               disabled={selectedStatus !== 'Gallery'} />    
                          </div>
                        </div>  
                  </div>  
                    
          </div> 
        </div> 
        <div className='mystudioDetails_table'> 
          <div className='mystudioDetails_table_left'> 
            <div className='submissionText'>
                SUBMISSION DETAILS
            </div>
            <div className='table_left_textfield'>  
              <div className='textfield_text'> 
                <input type='text' placeholder='Title' value={Title}  onChange={handleTitleChange} />    
              </div> 
              <div className='alertmessage'>{!isTitleValid && <div className='error-message'> !!! </div>}
              </div>
              <button onClick={clearText} className='showAll_button'>
                <div className='textfield_clearbuttonbox'> 
                  <div className='textfield_clearbutton'> 
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                      <path d="M6.5 18L18.5 6M6.5 6L18.5 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>

            <div className='selectbuttons'>
              <div className={`category_select ${selectedCategory ? 'selected' : ''}`}>
                <select onChange={handleCategoryChange}
                 value={selectedCategory} >
                  <option value="">Category </option>
                  {categories.map(category => (
                    <option key={category.id} value={category.id} className='option'>
                      {category.name}
                    </option>
                  ))}
                </select>
                {!isCategoryValid && <div className='error-message'> !!!</div>}
              </div>
              <div className={`category_select ${selectedColor ? 'selected' : ''}`}>
                <select onChange={handleColorChange}
                value={selectedColor}  >
                    <option value="">Color</option>
                    {colors.map(color => (
                      <option key={color.id} value={color.id}>
                        {color.name}
                      </option>
                    ))}
                  </select>    
                  {!isColorValid && <div className='error-message'> !!!</div>}
              </div>
              <div className={`category_select ${selectedDesignedFor ? 'selected' : ''}`}>
                <select onChange={handleDesignedForChange}     
                value={selectedDesignedFor} >
                    <option value="">Designed For</option>
                    {designedFor.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  {!isDesignedForValid && <div className='error-message'> !!!</div>}
              </div>
            </div>
            <div className='textfield_big'>
              <div className='alertmessage'>{!isDescriptionValid && <div className='error-message'> !!! </div>}
              </div>
              <div className='textfield_big_text'>
                <textarea 
                  name="postContent"    
                  placeholder='Description of the piece (inspiration, aesthetic, storytelling, etc). Max. 500 char.'
                  className='textfield_big_text' 
                  value={Description}   
                  onChange={handleDescriptionChange}  
                />  
                <div>  {Description &&  (Description.length /maxLength)}
                </div>
              </div>
             
            </div>
          </div>
          
          <div className='commentary_Column'>
              <div className='commentary_Header'>
                    COMMENTARY
              </div>
              <div className='commentary_Input'>
                  <textarea 
                    name="changeRequestContent"      
                    placeholder='change request note' 
                    value={selectedNote}   
                    onChange={handleNoteChange}  
                  />  
              </div> 
          </div>
         
          <div className='creationImages'> 
            <div className='creationImages_header'> 
              <div className='creationImages_header_titlebox'> 
                <div className='table_header_title'> 
                  SELECTED ({imagecounts})
                </div>  
                <div className='table_header_description'> 
                  Drag the files to change its order. First file will appear as the cover photo. 
                </div>  
              </div>
            </div>
          
            <div className='CreationDetails_table_AI_Preview'>
              <div className='mystudioDetails_table_corner_rounding'>  
                    {renderAllData()} 
              </div>
            </div>
             
          </div>
        </div>
      </div>
    </div>
  );
};
