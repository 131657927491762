import React, { useState, useEffect } from 'react'; 
import { useData } from '../config/DataContext';  
import { useLocation, useNavigate } from 'react-router-dom'; 
   
export default function Login() {
  return <MainComponent />;
}

const MainComponent = () => {   
  const [Email, setEmail] = useState(''); 
  const [isEmailValid, setisEmailValid] = useState(true);
  const [Password, setPassword] = useState('');  
  const [isPasswordValid, setisPasswordValid] = useState(true);
  const [isMessage, setisisMessage] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate(); 
  const configData = useData(); 
   

  const handleChange = (status, event) => { 
    if (status =='Email') 
    {
        setEmail(event.target.value);
        setisEmailValid(true);
    } else  if (status =='Password') 
    {
        setPassword(event.target.value);
        setisPasswordValid(true);
    }
  
  };
  
  const handleLinkClick = (status) => { 
    let path;
    if (status === 'pass') { 
      path = 'https://getyouniq.com/account/login#recover';
    } else if (status === 'create')
    {
      path = 'https://getyouniq.com/account/register';
      
    } 
    window.open(path);   
  };

  const handleLoginClick = async () => {  
    try { 
      let requestBody = {
        email: Email,
        password: Password   
      };
       
      const response = await fetch(`${configData.apiUrl}User/Authenticate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',  
          },
          body: JSON.stringify(requestBody),
        }
      );
       
      if (!response.ok) {
        setisisMessage("Unidentified customer - Username or password is incorrect");
        console.error('Error in response:', response.statusText);
        return;
      }
   
      const result = await response.json();
  
      
      localStorage.setItem('Authorization', result.type + ' ' + result.token);
      localStorage.setItem('expiresAt', result.expiresAt);
      localStorage.setItem('userType', result.userType);
 
      navigate('/');
    
    } catch (error) { 
      setisisMessage("Unidentified customer - Username or password is incorrect");
      console.error(error);
    }
  };
  
  return (
    <div className='content'>
      <div className='MyStudioContent'> 
         
        <div className='headerTextBlock'>
          <div className='headerTitle'> 
            A COMMUNITY OF FASHION GEEKS WITH BOLD CREATIVITY 
          </div> 
          <div className='headerText'> 
            Log-in to the tech-platform to have access to become a creator, 
            vote creations and engage with the community 
          </div> 
        </div> 
    
        
        <div className='loginTable'> 
           
          <div className='profileInformationTableLeft'>
            <div>
              <div className='profileInformationTableTitle'> 
                E-MAIL
              </div> 
              <div className='profileInformationTableLeftTextField'>  
                <div className='profiletextfield_text'> 
                  <input type='text' placeholder='E-mail' value={Email} 
                   onChange={(e) => handleChange('Email', e)}
                />    
                </div> 
                <div className='alertmessage'>{!isEmailValid && <div className='error-message'> !!! </div>}
                </div>
            
              </div>
            </div>
            <div>
              <div className='profileInformationTableTitle'> 
                PASSWORD
              </div> 
              <div className='profileInformationTableLeftTextField'>  
                <div className='profiletextfield_text'> 
                  <input  placeholder='Password' value={Password}  type='password' 
                   onChange={(e) => handleChange('Password', e)}
                  />    
                </div> 
                <div className='alertmessage'>{!isEmailValid && <div className='error-message'> !!! </div>}
                </div>
             
              </div>
              <div className='forgotPassText' onClick={() => handleLinkClick('pass')}> 
                Forgot password?
              </div> 
             
             
            </div>
     
            <div className='loginButtonBlock'> 
            {isMessage !== '' &&  (<div className='signInAlert'> 
                {isMessage}
              </div>)}
              <button  className='showAll_button' onClick={() => handleLoginClick()}  >
                <div className='signInButton'>
                   Sign In 
                </div> 
              </button>
             <div className='loginButtonBlockText'> or </div>
             <button  className='showAll_button'  onClick={() => handleLinkClick('create')}  >
               <div className='createAccount'>
                  Create Account
               </div> 
             </button>
          
           
           </div>
          </div> 
  
        </div>
        
      </div>
    </div>
  );
};
