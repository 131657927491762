import React, { useEffect, useState, useRef } from 'react';
import Header from '../components/Header';
import GetTokenBalance from '../components/GetTokenBalance';
import { useBalance } from '../components/BalanceContext';
import { useLocation, useNavigate } from 'react-router-dom';
import '../styles/figma.css';
import '../styles/figma_min.css';
import logo from '../images/Logo.png';

const MainLayout = ({ children }) => {
    const { tokenBalance } = useBalance();
    const [sticky, setSticky] = useState(false);
    const headerRef = useRef(null);
    const rolstatus = 'User';
    const location = useLocation();
    const isAIDesignPath = location.pathname === '/AIDesign';
    const isLogin = location.pathname === '/Login';
    const isSpotLightPath = location.pathname.endsWith('/spotlight') || location.pathname === '/SpotLight';

    const navigate = useNavigate();
    let userType = localStorage.getItem('userType'); 
    useEffect(() => { 
      const token = localStorage.getItem('Authorization');
    
      if (!token) {
        navigate('/Login');
      }
    }, [navigate]);

    useEffect(() => {
        const handleScroll = () => {
            if (headerRef.current) {
                const headerOffset = headerRef.current.offsetTop;
                if (window.pageYOffset > headerOffset) {
                    setSticky(true);
                } else {
                    setSticky(false);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={isSpotLightPath ? 'defaultwindowSpotLight' : 'defaultwindow'}>
            <div className={isSpotLightPath ? 'windowSpotLight' : 'window'}>
                {isAIDesignPath && rolstatus === '1' && (
                    <div className="buttons">
                        <div className='button_symbol'>
                            <span className='symbol'>
                                <GetTokenBalance />
                                {(tokenBalance !== null) ? (
                                    <div>{tokenBalance} </div>
                                ) : (
                                    <div>0 </div>
                                )}
                            </span>
                        </div>
                        <div className='icon_coin'>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 45" fill="none">
                                <path d="M6.6001 26.9V33.5C6.6001 37.1451 13.4949 40.1 22.0001 40.1C30.5053 40.1 37.4001 37.1451 37.4001 33.5V26.9C37.4001 30.5451 30.5053 33.5 22.0001 33.5C13.4949 33.5 6.6001 30.5451 6.6001 26.9Z" fill="#FEF6F6" />
                                <path d="M6.6001 15.9V22.5C6.6001 26.1451 13.4949 29.1 22.0001 29.1C30.5053 29.1 37.4001 26.1451 37.4001 22.5V15.9C37.4001 19.5451 30.5053 22.5 22.0001 22.5C13.4949 22.5 6.6001 19.5451 6.6001 15.9Z" fill="#FEF6F6" />
                                <path d="M37.4001 11.5C37.4001 15.1451 30.5053 18.1 22.0001 18.1C13.4949 18.1 6.6001 15.1451 6.6001 11.5C6.6001 7.85491 13.4949 4.89999 22.0001 4.89999C30.5053 4.89999 37.4001 7.85491 37.4001 11.5Z" fill="#FEF6F6" />
                            </svg>
                        </div>
                    </div>
                )}

                {userType === '1' && isLogin===false && (
                    <div className="buttons">
                        <div className='icons_avatar'>
                            <svg className="svg-icon" viewBox="0 0 20 20">
                                <path d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"></path>
                        </svg>
                        </div> 
                    </div>
                )}

 
                
                {!isSpotLightPath ? (
                    <header id="header"><Header rol={rolstatus} /></header>
                ) : (
                    <Header rol={rolstatus} />
                )}

                <div className='chat_table'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default MainLayout;
