import React, {useEffect} from 'react'; 
import { useNavigate } from 'react-router-dom';  
import '../styles/menu.css';

const Menu = ({ isPopupOpen, closePopup }) => {
  const navigate = useNavigate();  
  let userType = localStorage.getItem('userType'); 
  
  const handleOverlayClick = (e) => {
   
    if (e.target === e.currentTarget) {
      closePopup();
    }
  };

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const handleLinkClick = (path) => {
    closePopup();
    
    if (isMobile && window.ReactNativeWebView) {
      if (path === "/SpotLight") {
        window.ReactNativeWebView.postMessage('openNativeSpotlight');
      }
      else {
       
        navigate(path);}
    } else {

      navigate(path);
    }
  };

  const logOut = () => {
    localStorage.clear();
    closePopup();
    navigate("/Login");
  };
  
  if (!isPopupOpen) return null;

  return (
    <div className="popup-overlay" onClick={handleOverlayClick}>
      <div className="content_menu">
       <div className="content_menu_sub"> 
          <div className="menu_header">    
            <div className="menu_section_header"> 
              <div className="menu_section_header_text">
                MENU
              </div>
            </div>
            <div className='menu_separator'/> 
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
              <button  className='item_button'>
              <a href ="https://getyouniq.com/collections/the-lab"   >
                  Gallery
                </a></button>
          
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text"> 
                <button onClick={() => handleLinkClick("/")} className='item_button'>Creators</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
                <button onClick={() => handleLinkClick("/SpotLight")} className='item_button'>Spotlight</button>
    
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyStudio")} className='item_button'>My Studio</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/MyCreation")} className='item_button'>My Creations</button>
              </div>
            </div>
          </div>
          <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/AIDesign")} className='item_button'>AI Design</button>
              </div>
            </div>
          </div>
          {userType === '2' && ( <div className="menu_item">
            <div className="menu_item_title">
              <div className="menu_item_text">
              <button onClick={() => handleLinkClick("/CreationList")} className='item_button'>Creation List</button>
              </div>
            </div>
          </div>)}
       
        </div>

        <div className="userprofileItem">
          <div className="userprofileFoto"></div>
            <div className="userprofileTitle">
              <div className="userprofileText">
                Raquel García
              </div>
              <div className='logoutBlock' > <button onClick={() => logOut()} className='showAll_button   logoutBlock'>
              <svg fill="none" height="24" viewBox="0 0 24 24" width="24" 
              xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 
              18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" 
              stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></svg>
                 Logout</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
